// import i18n from '@/i18n/index'

export default {
  basicInformation: [{
    prop: 'user'
  }, {
    prop: 'name'
  }, {
    prop: 'phone_number'
  }, {
    prop: 'province'
  }, {
    prop: 'city'
  }, {
    prop: 'address'
  }, {
    prop: 'current',
    back: true
  }, {
    prop: 'region'
  }, {
    prop: 'created_at'
  }, {
    prop: 'updated_at'
  }, {
    prop: 'deleted_at'
  }, {
    prop: 'building_name'
  }, {
    prop: 'postal_code'
  }, {
    prop: 'unit_no'
  }, {
    prop: 'lat'
  }, {
    prop: 'lon'
  }, {
    prop: 'host'
  }, {
    prop: 'remarks'
  }, {
    prop: 'detailed_address'
  }, {
    prop: 'address_type'
  }, {
    prop: 'block_number'
  }]
}
